'use client';

import React from 'react';

import { Center, Container, VStack } from '@pt-group-fe/styled-system/jsx';

import { useTranslation } from '@lib/services/i18n/client';
import { Button } from '@lib/ui/button';
import { LayoutBoxSmaller, LayoutGrid } from '@lib/ui/layout';
import { Text } from '@lib/ui/text';

interface ErrorHandlerProps {
  error: Error;
  reset?: () => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({ error, reset, ...props }) => {
  const { t } = useTranslation();

  return (
    <Container {...props}>
      <LayoutGrid>
        <LayoutBoxSmaller>
          <Center>
            <VStack gap={6}>
              <Text variant="h2">{error.message || t('uiWeb.error.title')}</Text>
              <Text variant="block">{t('uiWeb.error.text')}</Text>
              <div>
                <Button size="small" icon="arrow-right" onClick={reset}>
                  {t('uiWeb.error.btnTryAgain')}
                </Button>
              </div>
            </VStack>
          </Center>
        </LayoutBoxSmaller>
      </LayoutGrid>
    </Container>
  );
};

export { ErrorHandler };
export type { ErrorHandlerProps };
